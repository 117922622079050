import "../css/tailwind.css";
import * as Sentry from "@sentry/node";
import { AppProps } from "next/app";

// Sentry.init({
//   enabled: process.env["NODE_ENV"] === "production",
//   dsn: process.env["NEXT_PUBLIC_SENTRY_DSN"],
// });

export default function App(props: AppProps) {
  const Component = props.Component;
  return <Component {...props.pageProps} />;
}
